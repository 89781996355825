import { Formik, FormikHelpers } from 'formik'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import checkIcon from '../../../assets/icons/check-icon.svg'
import { TextareaInput } from '../../../components/inputs/textarea-input'
import { NPSRatingModel } from '../../../models/request/coachee/nps-rating.model'
import { classNames } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { Line } from './coach.modal'
import { RateModal } from './rating.model'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const NPSRatingModal: React.FC<Props> = ({ isOpen, setIsOpen }) => {
    const { coachee } = useStores()
    const rates = [...Array(11)]

    const onSubmit = useCallback(
        (values: NPSRatingModel, helpers: FormikHelpers<NPSRatingModel>) => {
            helpers.setSubmitting(true)
            coachee.npsRating(values).subscribe({
                next(response) {
                    if (response.ok) {
                        setIsOpen(false)
                    }
                },
                complete() {
                    helpers.setSubmitting(false)
                },
            })
        },
        [coachee, setIsOpen],
    )

    const { t } = useTranslation()
    return (
        <Formik
            validate={validateModel}
            initialValues={new NPSRatingModel()}
            onSubmit={onSubmit}
        >
            {({
                handleSubmit,
                isValid,
                isValidating,
                isSubmitting,
                setFieldValue,
                submitForm,
                values,
            }) => (
                <RateModal
                    showTitle
                    isSubmitting={isSubmitting}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    onSubmit={submitForm}
                    disable={!isValid || isValidating || !values.rate}
                >
                    <form onSubmit={handleSubmit} data-testid="form">
                        <h2 className="text-[20px] md:text-[22px] text-center py-4 m-auto md:w-[350px]">
                            {t('modals.npsRating.title')}
                        </h2>
                        <div className="flex items-center  justify-center py-3 space-x-4">
                            {rates.map((_, index) => (
                                <div className="flex justify-center flex-col items-center">
                                    <span className="mb-2 font-[500] text-[13px] md:text-[15px]">
                                        {index}
                                    </span>
                                    <button
                                        key={index}
                                        type="button"
                                        onClick={() =>
                                            setFieldValue('rate', index)
                                        }
                                        className={classNames(
                                            `h-[25px] w-[25px] md:w-[50px] md:h-[50px] rounded-sm flex justify-center items-center text-[18px]`,
                                            index < 7
                                                ? 'bg-red-500'
                                                : index <= 8
                                                ? 'bg-yellow-500'
                                                : 'bg-green-500',
                                            values.rate !== null &&
                                                values.rate !== index &&
                                                'opacity-40',
                                        )}
                                    >
                                        {values.rate === index && (
                                            <img
                                                src={checkIcon}
                                                width={15}
                                                height={15}
                                            />
                                        )}
                                    </button>
                                </div>
                            ))}
                        </div>
                        <div className="flex items-center mb-8  justify-between">
                            <span className="text-[12px] md:text-[14px] font-semibold">
                                {t('modals.npsRating.notLikely')}
                            </span>
                            <span className="text-[12px] md:text-[14px] font-semibold">
                                {t('modals.npsRating.likely')}
                            </span>
                        </div>
                        {values && values.rate !== null && (
                            <div>
                                <Line />
                                <TextareaInput
                                    name="feedback"
                                    labelBig
                                    label={
                                        values.rate < 9
                                            ? t('modals.npsRating.label2')
                                            : (t(
                                                  'modals.npsRating.label',
                                              ) as string)
                                    }
                                    placeholder={
                                        t(
                                            'fields.feedback.placeholder',
                                        ) as string
                                    }
                                />
                            </div>
                        )}
                    </form>
                </RateModal>
            )}
        </Formik>
    )
}
