import classNames from 'classnames'
import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CompletedIcon from '../../../assets/icons/completed.svg'
import StartedIcon from '../../../assets/icons/goal-started.svg'
import NotStartedIcon from '../../../assets/icons/not-started.svg'
import PowerIcon from '../../../assets/icons/power.svg'
import SmileIcon from '../../../assets/icons/smile.svg'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { DatePickerComponent } from '../../../components/inputs/date-picker'
import { RangeInput } from '../../../components/inputs/range-input'
import { TextareaInput } from '../../../components/inputs/textarea-input'
import { CenterModal } from '../../../components/modals/center-modal'
import { GoalsModel } from '../../../models/request'
import { Goal } from '../../../models/response/coachee/goals'
import { trackEvent } from '../../../services/event-tracking'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    title: string
    goal?: Goal
}

export const GoalsModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen, title, goal }) => {
        const { t } = useTranslation()
        const [selectedDate, setSelectedDate] = useState<Date | null>(null)
        const { coachee } = useStores()

        const progressText = [
            t('goals.notStarted'),
            t('goals.started'),
            t('goals.halfWay'),
            t('goals.nearlyDone'),
            t('goals.done'),
        ]
        const progressIcon = [
            NotStartedIcon,
            StartedIcon,
            SmileIcon,
            PowerIcon,
            CompletedIcon,
        ]
        const handleDelete = useCallback(() => {
            if (goal) {
                coachee.deleteGoal(goal._id).subscribe({
                    next(response) {
                        if (response.ok) {
                            setIsOpen(false)
                        }
                    },
                })
            }
        }, [coachee, goal, setIsOpen])

        const onSubmit = useCallback(
            (values: GoalsModel, helpers: FormikHelpers<GoalsModel>) => {
                helpers.setSubmitting(true)

                if (goal) {
                    coachee.editGoal(values, goal._id).subscribe({
                        next(response) {
                            if (response.ok) {
                                trackEvent('screen_viewed', {
                                    name: 'goal_edit_saved',
                                    coachee_id: coachee.coachee?._id,
                                })
                                coachee.getCoacheeGoals().subscribe()
                                setIsOpen(false)
                            }
                        },
                        complete() {
                            helpers.setSubmitting(false)
                        },
                    })
                } else {
                    coachee.addGoal(values).subscribe({
                        next(response) {
                            if (response.ok) {
                                trackEvent('screen_viewed', {
                                    name: 'goal_create_saved',
                                    coachee_id: coachee.coachee?._id,
                                })
                                coachee.getCoacheeGoals().subscribe()
                                coachee.getChecklistEvent().subscribe()
                                setIsOpen(false)
                            }
                        },
                        complete() {
                            helpers.setSubmitting(false)
                        },
                    })
                }
            },
            [coachee, goal, setIsOpen],
        )

        useEffect(() => {
            if (goal && goal.dueDate) {
                setSelectedDate(goal.dueDate)
            }
        }, [goal])

        return (
            <CenterModal
                isOpen={isOpen}
                setIsOpen={() => setIsOpen(false)}
                className="md:max-w-[40rem] max-w-[34rem]"
                headerTitle={title}
            >
                <Formik
                    validate={validateModel}
                    initialValues={
                        new GoalsModel(
                            goal?.label,
                            goal?.progress,
                            goal?.dueDate,
                        )
                    }
                    onSubmit={onSubmit}
                >
                    {({
                        handleSubmit,
                        isSubmitting,
                        isValid,
                        isValidating,
                        setFieldValue,
                        values,
                    }) => (
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col px-8 py-4  bg-[#fafafa] mt-10 max-h-[650px] no-scrollbar md:max-h-[670px] overflow-y-auto"
                            data-testid="form"
                        >
                            <main className="space-y-2  lg:h-auto overflow-y-auto mb-6 mt-2">
                                <TextareaInput
                                    name="label"
                                    maxLength={100}
                                    labelBig
                                    label={t('fields.goal.label') as string}
                                    placeholder={
                                        t('fields.goal.placeholder') as string
                                    }
                                    className="bg-white focus:outline-none text-[17px] h-[8rem]"
                                    showCharaterLeft
                                />

                                <div className="flex flex-col pt-6">
                                    <span>{t('goals.trackProgress')}</span>
                                    <img
                                        src={progressIcon[values.progress / 25]}
                                        className="w-12 h-12 my-6 mx-auto"
                                    />
                                    <div className="flex justify-center items-center mt-2 text-sm">
                                        <span>
                                            {progressText[values.progress / 25]}
                                        </span>
                                    </div>
                                    <RangeInput
                                        name="progress"
                                        min={0}
                                        max={100}
                                        step={25}
                                    />
                                </div>

                                <div className="flex justify-between pt-6 pb-4">
                                    <span className="text-xl mb-2">
                                        {t('goals.due')}
                                    </span>
                                    <DatePickerComponent
                                        name="dueDate"
                                        selectedDate={selectedDate}
                                        setSelectedDate={setSelectedDate}
                                        setFieldValue={setFieldValue}
                                        coachee={coachee}
                                    />
                                </div>
                            </main>
                            <footer
                                className={classNames(
                                    !goal && 'my-4',
                                    'flex flex-col items-center justify-center',
                                )}
                            >
                                <PrimaryButton
                                    className="w-full"
                                    type="submit"
                                    loading={isSubmitting}
                                    disabled={!isValid || isValidating}
                                >
                                    <span>{t('buttons.save')}</span>
                                </PrimaryButton>
                                {goal && (
                                    <span
                                        className="text-[#ff0000] cursor-pointer my-6"
                                        onClick={handleDelete}
                                    >
                                        {t('buttons.delete')}
                                    </span>
                                )}
                            </footer>
                        </form>
                    )}
                </Formik>
            </CenterModal>
        )
    },
)
