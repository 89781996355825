import { LoginCallback } from '@okta/okta-react'
import * as Sentry from '@sentry/browser'
import { useEffect } from 'react'
import { RouteLink } from '../../../utils/constants'
import { history } from '../../../utils/misc/history'

const OktaLoginCallbackErrors = ({ error }: { error: Error }) => {
    useEffect(() => {
        Sentry.captureException(error)
        let errorMessage = encodeURIComponent(error.message)

        if (
            error.message ===
            'The client specified not to prompt, but the user is not logged in.'
        ) {
            errorMessage = 'The user is not logged in.'
        }

        history.replace({
            pathname: RouteLink.OKTA_LOGIN,
            search: `?error=${errorMessage}`,
        })
    }, [error])

    return null
}

export const OktaLoginCallback = () => {
    return <LoginCallback errorComponent={OktaLoginCallbackErrors} />
}
