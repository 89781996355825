import { IsBoolean, IsNumber, IsOptional, IsString } from 'class-validator'
import { IsFormData } from '../../../utils/validation/decorators'
import { BaseModel } from '../base.model'

export class ChatModel implements BaseModel {
    constructor() {}

    @IsOptional()
    @IsString()
    public message: string = ''

    @IsOptional()
    @IsFormData()
    public file: FormData | null = null

    @IsOptional()
    @IsBoolean()
    public isUrgent: boolean = false

    @IsOptional()
    @IsNumber()
    public fileBytesSize: number = 0

    @IsOptional()
    @IsNumber()
    public duration: number = 0

    public getRequestBody() {
        return {
            isUrgent: this.isUrgent,
            fileBytesSize: this.fileBytesSize,
        }
    }
}
