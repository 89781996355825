import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import StarEmptyIcon from '../../../assets/icons/star-empty2.svg'
import StarIcon from '../../../assets/icons/star-full.svg'
import { TextareaInput } from '../../../components/inputs/textarea-input'
import { RateCoachModel } from '../../../models/request/coachee/rate-coach.model'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { Line } from './coach.modal'
import { RateModal } from './rating.model'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const RateCoachModal: React.FC<Props> = observer(
    ({ isOpen, setIsOpen }) => {
        const { t } = useTranslation()
        const { coachee } = useStores()
        const stars = [...Array(5)]

        const onSubmit = useCallback(
            (
                values: RateCoachModel,
                helpers: FormikHelpers<RateCoachModel>,
            ) => {
                helpers.setSubmitting(true)
                coachee.rateCoach(values).subscribe({
                    next(response) {
                        if (response.ok) {
                            setIsOpen(false)
                        }
                    },
                    complete() {
                        helpers.setSubmitting(false)
                    },
                })
            },
            [coachee, setIsOpen],
        )

        return (
            <Formik
                validate={validateModel}
                initialValues={new RateCoachModel()}
                onSubmit={onSubmit}
            >
                {({
                    handleSubmit,
                    isValid,
                    isValidating,
                    setFieldValue,
                    submitForm,
                    values,
                    isSubmitting,
                }) => (
                    <RateModal
                        showTitle={false}
                        isOpen={isOpen}
                        setIsOpen={setIsOpen}
                        onSubmit={submitForm}
                        disable={!isValid || isValidating || values.rate === 0}
                        isSubmitting={isSubmitting}
                    >
                        <form onSubmit={handleSubmit} data-testid="form">
                            <div className="flex flex-col justify-center items-center">
                                <span>{t('modals.rateCoach.subTitle')}</span>
                                <div className="flex gap-2 my-4">
                                    {stars.map((_, i) => (
                                        <button
                                            key={i}
                                            onClick={() => {
                                                setFieldValue('rate', i + 1)
                                            }}
                                            type="button"
                                        >
                                            {i < values.rate ? (
                                                <img
                                                    src={StarIcon}
                                                    className="w-10 h-10"
                                                    alt={t('alt.rating')}
                                                />
                                            ) : (
                                                <img
                                                    src={StarEmptyIcon}
                                                    className="w-10 h-10"
                                                    alt={t('alt.rating')}
                                                />
                                            )}
                                        </button>
                                    ))}
                                </div>
                                <span>
                                    {t('modals.rateCoach.rate', {
                                        rate: values.rate,
                                    })}
                                </span>
                            </div>
                            {values.rate < 5 && values.rate !== 0 && (
                                <div>
                                    <Line />
                                    <TextareaInput
                                        name="feedback"
                                        labelBig
                                        label={
                                            t('fields.feedback.label') as string
                                        }
                                        placeholder={
                                            t(
                                                'fields.feedback.placeholder',
                                            ) as string
                                        }
                                    />
                                </div>
                            )}
                        </form>
                    </RateModal>
                )}
            </Formik>
        )
    },
)
