import { observer } from 'mobx-react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { Participant } from '../../../models/response/booking'
import { classNames } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { InviteGuestModal } from '../modals/invite-guest.modal'
import Close from '/src/assets/icons/close-guest.svg'

type Props = {
    label: string
    handleClick?: (value: React.SetStateAction<string>) => void
    handleSubmit?: (e: { preventDefault: () => void }) => void
    isClicked?: boolean
    setParticipants?: (value: React.SetStateAction<Participant[]>) => void
    participants?: Participant[]
    isSubmitting?: boolean
}

const InviteGuests: React.FC<Props> = observer(
    ({ label, handleSubmit, setParticipants, participants, isSubmitting }) => {
        const { coachee } = useStores()
        const { t } = useTranslation()

        const [isModalVisible, setIsModalVisible] = useState(false)

        return (
            <div className="lg:px-6 px-4 m-auto">
                <div className="h-auto flex-grow-0 m-10 mt-0 mx-auto p-0 pt-0 lg:px-9 px-4 pb-14 rounded-lg bg-white">
                    <span className="flex font-normal mb-2 lg:text-3xl text-xl pt-6 px-3 pb-6">
                        {label}
                    </span>
                    <div className="h-[1px] flex-grow-0 ml-[10px] mr-[10px]  mb-[20px] bg-[#d2d9da]"></div>
                    {participants?.length === 0 ? (
                        <div className="flex items-center justify-center space-x-4">
                            {coachee.coachee?.user.company.settings
                                .sessionGuests.canInvite && (
                                <button
                                    className={classNames(
                                        'w-[208px] h-[60px] flex justify-center items-center',
                                        'rounded-full bg-[#f3f4f4] text-primary hover:bg-primary hover:text-white',
                                        'hover:scale-105',
                                        'transition-transform duration-300 ease-in-out',
                                    )}
                                    onClick={() => setIsModalVisible(true)}
                                >
                                    <span className="text-[17px] text-center">
                                        {t('booking.addColleague')}
                                    </span>
                                </button>
                            )}
                            <PrimaryButton
                                onClick={handleSubmit}
                                type="submit"
                                className={classNames(
                                    'btn-default',
                                    'w-[208px] h-[60px] flex justify-center items-center',
                                    'rounded-full hover:bg-primary hover:text-white',
                                    'hover:scale-105',
                                    'transition-transform duration-300 ease-in-out',
                                )}
                                disabled={isSubmitting}
                            >
                                <span
                                    className={classNames(
                                        'text-[17px] text-center',
                                    )}
                                >
                                    {t('booking.bookSession')}
                                </span>
                            </PrimaryButton>
                        </div>
                    ) : (
                        <div>
                            <div className="mx-auto md:w-[450px]  h-auto rounded-full bg-[#f3f4f4] mt-6 ">
                                <div className="flex flex-wrap items-center justify-center space-x-4 rounded-full p-2">
                                    {participants?.map((participant, index) => (
                                        <div
                                            key={index}
                                            className="w-[218px] h-[50px] flex items-center justify-center space-x-4 rounded-full bg-white m-2 p-4"
                                        >
                                            <span
                                                className={classNames(
                                                    'text-[17px] text-center',
                                                    'overflow-hidden whitespace-nowrap overflow-ellipsis',
                                                )}
                                                title={participant.email}
                                            >
                                                {participant.email}
                                            </span>
                                            <img
                                                src={Close}
                                                alt="Icon close"
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    if (setParticipants) {
                                                        setParticipants(
                                                            participants?.filter(
                                                                (_, i) =>
                                                                    i !== index,
                                                            ),
                                                        )
                                                    }
                                                }}
                                            />
                                        </div>
                                    ))}
                                    <button
                                        className="w-[130px] h-[65px] rounded-full"
                                        onClick={() => setIsModalVisible(true)}
                                        disabled={participants?.length === 1}
                                    >
                                        <span className="text-accent-2 text-[17px] text-center">
                                            {t('booking.addColleague')}
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-center space-x-4 mt-6">
                                <PrimaryButton
                                    onClick={handleSubmit}
                                    type="submit"
                                    className={classNames(
                                        'btn-default',
                                        'w-[208px] h-[60px] flex justify-center items-center',
                                        'rounded-full hover:bg-primary hover:text-white',
                                        'hover:scale-105',
                                        'transition-transform duration-300 ease-in-out',
                                    )}
                                    disabled={isSubmitting}
                                >
                                    <span
                                        className={classNames(
                                            'text-[17px] text-center',
                                        )}
                                    >
                                        {t('booking.bookSession')}
                                    </span>
                                </PrimaryButton>
                            </div>
                        </div>
                    )}
                </div>
                <InviteGuestModal
                    isOpen={isModalVisible}
                    setIsOpen={() => setIsModalVisible(false)}
                    setParticipants={setParticipants}
                />
            </div>
        )
    },
)

export default InviteGuests
