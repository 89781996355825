import classNames from 'classnames'
import { isPast } from 'date-fns'
import { observer } from 'mobx-react'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import InfoIcon from '../../../assets/icons/exclamation-mark.svg'
import { Goal } from '../../../models/response/coachee/goals'
import { trackEvent } from '../../../services/event-tracking'
import { formatDate, getFormatString } from '../../../utils/misc'
import { GoalsModal } from '../modals/goals.modal'

interface Props {
    goal: Goal
}

export const GoalCard: React.FC<Props> = observer(({ goal }) => {
    const { t } = useTranslation()
    const [openEdit, setOpenEdit] = useState(false)

    const isOverDue = useMemo(() => {
        if (goal.dueDate && !goal.completedAt) {
            return isPast(goal.dueDate)
        }

        return false
    }, [goal.completedAt, goal.dueDate])

    return (
        <div
            className={classNames(
                !goal.completedAt && 'border-[#d2d9da]',
                isOverDue && 'border-[#ff7558]',
                goal.completedAt && 'border-white',
                'flex flex-col max-w-[22rem] p-6 mx-auto shadow-md',
                'bg-white border-l-4 mt-4 rounded-lg cursor-pointer',
            )}
            onClick={() => {
                trackEvent('screen_viewed', {
                    name: 'goal_edit_opened',
                })
                setOpenEdit(true)
            }}
        >
            <span className="line-clamp-1">{goal.label}</span>
            <div className="flex gap-2">
                {isOverDue && <img src={InfoIcon} className="w-4 h-4 mt-1" />}
                <span className="text-[#6b6a72] text-base">
                    {goal.completedAt &&
                        t('goals.completedAt', {
                            date: formatDate(
                                goal.completedAt,
                                getFormatString('long'),
                            ),
                        })}
                    {goal.dueDate &&
                        !goal.completedAt &&
                        !isOverDue &&
                        t('goals.dueDate', {
                            date: formatDate(
                                goal.dueDate,
                                getFormatString('long'),
                            ),
                        })}
                    {goal.dueDate &&
                        !goal.completedAt &&
                        isOverDue &&
                        t('goals.overdue', {
                            date: formatDate(
                                goal.dueDate,
                                getFormatString('long'),
                            ),
                        })}
                    {!goal.dueDate && !goal.completedAt && t('goals.noDueDate')}
                </span>
            </div>

            <div className="h-1 mt-2 bg-[#f3f4f4] rounded-full">
                <div
                    style={{ width: `${goal.progress}%` }}
                    className="h-full bg-[#236978] rounded-full"
                />
            </div>
            {openEdit && (
                <GoalsModal
                    isOpen={openEdit}
                    setIsOpen={setOpenEdit}
                    title={t('goals.editGoal')}
                    goal={goal}
                />
            )}
        </div>
    )
})
