import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { CenterModal } from '../../../components/modals'
import { Line } from './coach.modal'

interface Props {
    isOpen: boolean
    isSubmitting: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
    children: React.ReactNode
    onSubmit: () => void
    disable: boolean
    showTitle: boolean
}

export const RateModal: React.FC<Props> = observer(
    ({
        isOpen,
        setIsOpen,
        children,
        onSubmit,
        disable,
        isSubmitting,
        showTitle,
    }) => {
        const { t } = useTranslation()

        return (
            <div>
                {isOpen && (
                    <CenterModal
                        isOpen={isOpen}
                        setIsOpen={() => setIsOpen(false)}
                        className="max-w-[42rem] py-2 px-5"
                    >
                        <header>
                            {showTitle && (
                                <div className=" text-xl font-bold">
                                    <span>
                                        {t('modals.rateCoach.title') ||
                                            t('modals.npsRating.header')}
                                    </span>
                                </div>
                            )}
                            <Line />
                        </header>
                        <main>{children}</main>
                        <footer className="py-5">
                            <PrimaryButton
                                className="w-full"
                                onClick={onSubmit}
                                disabled={disable}
                            >
                                {isSubmitting
                                    ? `${t('messages.loading')}...`
                                    : t('buttons.rate')}
                            </PrimaryButton>
                        </footer>
                    </CenterModal>
                )}
            </div>
        )
    },
)
