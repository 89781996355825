import { toRelativeUrl } from '@okta/okta-auth-js'
import { Security } from '@okta/okta-react'
import { once } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useNavigate } from 'react-router-dom'
import './App.css'
import { NotificationArea } from './components/layout/notifications'
import { Router } from './components/routing'
import { oktaAuthInfo } from './oktaConfig'
import { routes } from './pages/routes'
import {
    PushNotificationInit,
    PushNotificationSetup,
} from './services/push-notification'
import { SentryInit } from './services/sentry'
import { dehydrateToStorage, hydrateFromStorage } from './utils/misc'

export default function App(): ReactElement {
    const [setupCompleted, setSetupcomplete] = useState(false)
    const navigate = useNavigate()

    const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
        navigate(toRelativeUrl(originalUri || '/', window.location.origin))
    }

    useEffect(() => {
        const setupFunctions = once(async () => {
            await PushNotificationInit()
            SentryInit()
            setSetupcomplete(true)
        })
        if (!setupCompleted) {
            setupFunctions().then(() => PushNotificationSetup())
        }
    }, [setupCompleted])

    useEffect(() => {
        const generateDeviceId = () => {
            return crypto.randomUUID()
        }

        const getDeviceId = async () => {
            let storedDeviceId: string | null = hydrateFromStorage('deviceId')

            if (!storedDeviceId) {
                storedDeviceId = generateDeviceId()
                dehydrateToStorage('deviceId', storedDeviceId)
                return storedDeviceId
            }
        }

        getDeviceId()
    }, [])

    return (
        <Security
            oktaAuth={oktaAuthInfo}
            restoreOriginalUri={restoreOriginalUri}
        >
            <HelmetProvider>
                <Router routes={routes} />
                <NotificationArea />
            </HelmetProvider>
        </Security>
    )
}
