import classNames from 'classnames'
import { Formik, FormikHelpers } from 'formik'
import { observer } from 'mobx-react'
import { ChangeEvent, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AttachIcon from '../../../assets/icons/attachment.svg'
import urgentIconActive from '../../../assets/icons/info-icon.svg'
import micIcon from '../../../assets/icons/mic.svg'
import sendIcon from '../../../assets/icons/send.svg'
import urgentIcon from '../../../assets/icons/urgent.svg'
import { TextareaInput } from '../../../components/inputs/textarea-input'
import { ChatModel } from '../../../models/request/chat'
import { trackEvent } from '../../../services/event-tracking'
import {
    countWords,
    isAllowedAttachmentContentType,
} from '../../../utils/chatHelper'
import { NotificationType } from '../../../utils/constants'
import { useStores } from '../../../utils/stores'
import { validateModel } from '../../../utils/validation/validate-model'
import { FileLoader } from './uploadLoader'
import { VoiceRecord } from './voiceRecord'

export const SendChatSection: React.FC = observer(() => {
    const { t } = useTranslation()
    const { channel, notifications, coachee } = useStores()
    const [showAudio, setShowAudio] = useState(false)

    const [fileInfo, setFileInfo] = useState({
        filename: '',
        progress: 0,
        uploading: false,
    })
    const textAreaRef = useRef<HTMLTextAreaElement>(null)

    const onSubmit = useCallback(
        (values: ChatModel, helpers: FormikHelpers<ChatModel>) => {
            helpers.setSubmitting(true)
            if (values.message) {
                channel.postChatMessage(values).subscribe({
                    next(response) {
                        if (response.ok) {
                            trackEvent('chat_message_sent', {
                                coach_id: coachee.coachee?.currentCoach._id,
                                coachee_id: coachee.coachee?._id,
                                is_urgent: values.isUrgent,
                                nb_word: countWords(values.message),
                            })
                        }

                        if (response.ok && values.isUrgent) {
                            notifications.createNotification(
                                NotificationType.INFO,
                                t('messages.urgentMessage'),
                                5 * 1000 /* 5 seconds */,
                                true,
                            )
                        }
                    },
                    complete() {
                        helpers.setSubmitting(false)
                        helpers.resetForm()
                        textAreaRef.current?.style.setProperty(
                            'height',
                            '4.5rem',
                        )
                    },
                })
            } else {
                helpers.setSubmitting(false)
                helpers.resetForm()
            }
        },
        [
            channel,
            coachee.coachee?._id,
            coachee.coachee?.currentCoach._id,
            notifications,
            t,
        ],
    )

    const handleFileUpload = (
        e: ChangeEvent<HTMLInputElement>,
        values: ChatModel,
    ) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            const fileSize = file.size
            if (isAllowedAttachmentContentType(file.type)) {
                const formData = new FormData()
                formData.append('file', file)

                setFileInfo({
                    filename: file.name,
                    progress: 50,
                    uploading: true,
                })

                const chatModel = new ChatModel()
                chatModel.file = formData
                chatModel.fileBytesSize = fileSize
                chatModel.isUrgent = values.isUrgent

                channel.postChatMessage(chatModel).subscribe({
                    next(response) {
                        if (response.ok) {
                            trackEvent('chat_message_sent', {
                                coach_id: coachee.coachee?.currentCoach._id,
                                coachee_id: coachee.coachee?._id,
                                is_urgent: values.isUrgent,
                            })
                        }
                        if (response.ok && values.isUrgent) {
                            notifications.createNotification(
                                NotificationType.INFO,
                                t('messages.urgentMessage'),
                                5 * 1000 /* 5 seconds */,
                                true,
                            )
                        }
                    },
                    complete() {
                        setFileInfo({
                            filename: file.name,
                            progress: 100,
                            uploading: false,
                        })
                    },
                })
            } else {
                notifications.createNotification(
                    NotificationType.ERROR,
                    t('chat.wrongFileFormat'),
                    5 * 1000 /* 5 seconds */,
                )
            }
        }
    }

    const handleTextChange = (
        e: ChangeEvent<HTMLTextAreaElement>,
        setFieldValue: (field: string, value: any) => void,
    ) => {
        e.target.style.height = 'auto'
        e.target.style.height = e.target.scrollHeight + 'px'
        e.target.style.borderRadius = `1.8rem`
        setFieldValue('message', e.target.value)
    }

    return (
        <div className="lg:py-4">
            <Formik
                validate={validateModel}
                initialValues={new ChatModel()}
                onSubmit={onSubmit}
            >
                {({
                    handleSubmit,
                    isSubmitting,
                    isValid,
                    setFieldValue,
                    values,
                }) => {
                    return (
                        <form
                            onSubmit={handleSubmit}
                            className="flex flex-col bg-white  fixed bottom-0 w-full lg:w-[calc(100vw_-_310px)] md:w-[calc(100vw_-_250px)]"
                            data-testid="form"
                        >
                            {fileInfo.uploading && (
                                <FileLoader
                                    progress={fileInfo.progress}
                                    filename={fileInfo.filename}
                                />
                            )}
                            {showAudio ? (
                                <VoiceRecord
                                    close={() => setShowAudio(false)}
                                />
                            ) : (
                                <main className="px-4 md:px-8 py-4 flex relative space-x-4 ">
                                    <img
                                        src={
                                            values.isUrgent
                                                ? urgentIconActive
                                                : urgentIcon
                                        }
                                        alt="ugrent icon"
                                        className="w-6 h-6 md:w-7 absolute bottom-10 left-2 md:h-7 cursor-pointer mt-2.5"
                                        onClick={() =>
                                            setFieldValue(
                                                'isUrgent',
                                                !values.isUrgent,
                                            )
                                        }
                                    />
                                    <div className="relative flex-grow">
                                        <TextareaInput
                                            textAreaRef={textAreaRef}
                                            name="message"
                                            customOnChangeHandler={(e) =>
                                                handleTextChange(
                                                    e,
                                                    setFieldValue,
                                                )
                                            }
                                            className={classNames(
                                                'rounded-[3.5rem] pt-[25px] ml-[6px] lg:ml-0 text-[17px]',
                                                'px-6 md:px-8 outline-none md:w-[97%] w-[94%]',
                                            )}
                                            isChat
                                            placeholder={
                                                t(
                                                    'fields.message.placeholder',
                                                ) as string
                                            }
                                        />
                                        <div
                                            className={classNames(
                                                'absolute right-3 lg:right-10  bottom-[6px]  transform -translate-y-1/2',
                                                'flex items-center gap-4 mr-4',
                                            )}
                                        >
                                            <div>
                                                <label htmlFor="file-upload">
                                                    <img
                                                        src={AttachIcon}
                                                        alt="attachment"
                                                        className="w-4 h-4 cursor-pointer"
                                                    />
                                                </label>
                                                <input
                                                    id="file-upload"
                                                    type="file"
                                                    name="file"
                                                    onChange={(e) =>
                                                        handleFileUpload(
                                                            e,
                                                            values,
                                                        )
                                                    }
                                                    className="hidden"
                                                />
                                            </div>
                                            <img
                                                onClick={() =>
                                                    setShowAudio(true)
                                                }
                                                src={micIcon}
                                                alt="mic"
                                                className="w-4 h-4 cursor-pointer"
                                            />
                                        </div>
                                    </div>

                                    <button
                                        type="submit"
                                        disabled={isSubmitting || !isValid}
                                    >
                                        <img
                                            className="w-[30px] absolute bottom-8 lg:right-8 right-4 md:w-[40px]"
                                            src={sendIcon}
                                            alt="send"
                                        />
                                    </button>
                                </main>
                            )}
                        </form>
                    )
                }}
            </Formik>
        </div>
    )
})
