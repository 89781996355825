import { t } from 'i18next'
import OneSignal from 'react-onesignal'
import { stores } from '../utils/stores'

export interface PushNotificationSettingsRequest {
    accepted: boolean
    playerId: string
    deviceId: string
}

function pushSubscriptionChangeListener(event: any) {
    if (event.current.token) {
        if (stores.coachee.coachee) {
            updateDetails()
            stores.coachee.updatePushNotificationSettings({
                accepted: OneSignal.User.PushSubscription.optedIn ?? false,
                playerId: event.current.playerId,
                deviceId: event.current.deviceId,
            })
        }
    }
}

export const updateDetails = async () => {
    if (stores.coachee.coachee) {
        // OneSignal.User.addEmail(stores.coachee.coachee.user.email)

        OneSignal.User.addTag(
            'companyId',
            stores.coachee.coachee.user.company._id,
        )
        OneSignal.User.addTag('coacheeId', stores.coachee.coachee._id)
        OneSignal.User.addTag('userId', stores.coachee.coachee.user._id)
        OneSignal.login(stores.coachee.coachee._id)
    }
}

export const PushNotificationInit = async () => {
    if (import.meta.env.VITE_APP_NODE_ENV !== 'development') {
        await OneSignal.init({
            path: '/',
            serviceWorkerPath: '/',
            appId: import.meta.env.VITE_APP_ONE_SIGNAL_APP_ID,
            safari_web_id: import.meta.env.VITE_APP_ONE_SIGNAL_SAFARI_WEB_ID,
            // allowLocalhostAsSecureOrigin: true,
            promptOptions: {
                slidedown: {
                    enabled: false,
                    autoPrompt: false,
                    actionMessage: t('notifications.slidedown.actionMessage'),
                    acceptButtonText: t(
                        'notifications.slidedown.acceptButtonText',
                    ),
                    cancelButtonText: t(
                        'notifications.slidedown.cancelButtonText',
                    ),
                },
            },
        })
    }
}

export const PushNotificationSetup = async () => {
    console.log('PushNotificationSetup Called')
    if (import.meta.env.VITE_APP_NODE_ENV !== 'production') {
        OneSignal.Debug.setLogLevel('trace')
    }
    if (window.location.pathname === '/dashboard') {
        if (!OneSignal.User.PushSubscription.optedIn) {
            OneSignal.Slidedown.promptPush()
        } else {
            updateDetails()
        }
        OneSignal.User.PushSubscription.addEventListener(
            'change',
            pushSubscriptionChangeListener,
        )
        OneSignal.Notifications.addEventListener(
            'foregroundWillDisplay',
            (event) => {
                console.log(
                    '=================NOTIFICATION SHOWING=================',
                )
                console.log(event)
            },
        )
    }
}
