import { observer } from 'mobx-react'
import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { Booking } from '../../../models/response'
import { trackEvent } from '../../../services/event-tracking'
import { sessionStartSoon } from '../../../utils/misc'
import { history } from '../../../utils/misc/history'
import { useStores } from '../../../utils/stores'
import { InviteGuestModal } from '../../booking/modals/invite-guest.modal'
import { ConfirmationModal } from '../modals'
import { GuestModal } from '../modals/guest.modal'
import { SessionDate } from './sessionDate'

interface Props {
    booking: Booking
}

export const BookingCard: React.FC<Props> = observer(({ booking }) => {
    const [openGuest, setOpenGuest] = useState(false)
    const { coachee } = useStores()
    const { t } = useTranslation()
    const { booking: storeBooking } = useStores()
    const [openRemoveBooking, setOpenRemoveBooking] = useState(false)
    const [openInviteGuest, setOpenInviteGuest] = useState(false)
    const [showJoinButton, setShowJoinButton] = useState(false)
    const [openReschedule, setOpenReschedule] = useState(false)

    const handleDelete = useCallback(() => {
        storeBooking.deleteBooking(booking._id).subscribe({
            next: (response) => {
                if (response.ok) {
                    trackEvent('session_cancelled', {
                        coach_id: coachee.coachee?.currentCoach?._id,
                        coachee_id: coachee.coachee?._id,
                        booking_id: booking._id,
                        date: booking.date,
                        duration: booking.duration,
                        is_video_session: booking.isVideoSession,
                    })
                    coachee.getCoachee().subscribe()
                }
            },
        })
    }, [
        booking._id,
        booking.date,
        booking.duration,
        booking.isVideoSession,
        coachee,
        storeBooking,
    ])

    const bookingDate = new Date(booking.date)
    const currentDate = new Date()
    const isLessThan24Hours =
        bookingDate.getTime() - currentDate.getTime() < 24 * 60 * 60 * 1000
    useEffect(() => {
        const checkIfSessionStartsSoon = () => {
            const startsSoon = sessionStartSoon(
                booking.date,
                coachee.coacheeUser?._timezone,
            )
            setShowJoinButton(startsSoon)
        }

        checkIfSessionStartsSoon()
        const intervalId = setInterval(checkIfSessionStartsSoon, 60000)

        return () => clearInterval(intervalId)
    }, [booking.date, coachee.coacheeUser?._timezone])

    const startSession = useCallback(() => {
        storeBooking.getSessionUrl(booking._id).subscribe({
            next(response) {
                if (response.data) {
                    window.open(response.data.url, '_blank')
                }
            },
        })
    }, [booking._id, storeBooking])

    const handleReschedule = () => {
        history.push(`/newBooking?bookingId=${booking._id}`)
    }

    return (
        <>
            <div className="bg-white px-4 py-2 mx-2 mt-4 rounded-md ">
                <div className="flex items-center">
                    <span className="text-[#6b6a72] text-[17px] px-2">
                        {t('dashboard.session.coachingSession')}
                    </span>
                    <span className="text-[#6b6a72] text-[17px] mb-2">.</span>
                    <span className="text-[#6b6a72] text-[17px] px-2">
                        {t('dashboard.session.duration', {
                            mins: booking?.duration,
                        })}
                    </span>
                    <span className="text-[#6b6a72] text-[17px] mb-2">.</span>
                    <span className="text-[#6b6a72] text-[17px] px-2">
                        {booking?.isVideoSession
                            ? t('dashboard.session.video')
                            : t('dashboard.session.audio')}
                    </span>
                </div>

                <SessionDate
                    date={booking.date}
                    className="text-[#090714] text-[22px] px-2"
                />

                <div className="bg-white px-2 py-2 mt-1 rounded-md flex flex-col items-center justify-center">
                    {showJoinButton && (
                        <PrimaryButton
                            className="w-full"
                            onClick={startSession}
                        >
                            <span> {t('buttons.joinSession')}</span>
                        </PrimaryButton>
                    )}

                    <div className="flex-grow h-[1px] bg-[#e9eced] mt-4 w-full"></div>

                    <div className="flex text-sm gap-6 mt-4">
                        {!(
                            coachee.coachee?.user.company.settings.sessionGuests
                                .canInvite === false &&
                            booking.participants.length === 0
                        ) && (
                            <>
                                <span
                                    className="cursor-pointer text-[17px] text-[#002b38] "
                                    onClick={() => {
                                        if (booking.participants.length === 0) {
                                            setOpenInviteGuest(true)
                                        } else {
                                            setOpenGuest(true)
                                        }
                                    }}
                                >
                                    {booking.participants.length === 0
                                        ? t('dashboard.session.guest')
                                        : t('dashboard.session.guestLength', {
                                              length: booking.participants
                                                  .length,
                                          })}
                                </span>
                                <span className="border-l border-[#e9eced] h-[19px] inline-block mt-1"></span>
                            </>
                        )}
                        {isLessThan24Hours ? (
                            <span
                                className="cursor-pointer text-[#002b38] text-[17px]"
                                onClick={() => setOpenReschedule(true)}
                            >
                                {t('dashboard.session.reschedule')}
                            </span>
                        ) : (
                            <span>
                                <Link
                                    className="text-[17px] text-[#002b38]"
                                    to={`/newBooking?bookingId=${booking._id}`}
                                >
                                    {t('dashboard.session.reschedule')}
                                </Link>
                            </span>
                        )}
                        <span className="border-l border-[#e9eced] h-[19px] inline-block mt-1"></span>
                        <span
                            className="text-[#ff0000] cursor-pointer text-[17px]"
                            onClick={() => setOpenRemoveBooking(true)}
                        >
                            {t('dashboard.session.cancel')}
                        </span>
                    </div>
                </div>
            </div>

            {openGuest && booking.participants.length !== 0 && (
                <GuestModal
                    isOpen={openGuest}
                    setIsOpen={setOpenGuest}
                    participants={booking.participants}
                    bookingId={booking._id}
                />
            )}
            {openRemoveBooking && (
                <ConfirmationModal
                    isOpen={openRemoveBooking}
                    setIsOpen={setOpenRemoveBooking}
                    onClick={handleDelete}
                    message={
                        isLessThan24Hours
                            ? t('modals.removeBooking.lateCancelHeading')
                            : t('modals.removeBooking.heading')
                    }
                    actionBtnText={t('buttons.cancelSession')}
                    actionBtnCancelText={t('buttons.keepSession')}
                    mainMessage={
                        isLessThan24Hours
                            ? t('modals.removeBooking.mainMessage')
                            : undefined
                    }
                    textHeader={
                        isLessThan24Hours
                            ? t('modals.removeBooking.textHeader')
                            : undefined
                    }
                />
            )}

            {openReschedule && (
                <ConfirmationModal
                    isOpen={openReschedule}
                    setIsOpen={setOpenReschedule}
                    onClick={handleReschedule}
                    message={t('modals.rescheduleBooking.heading')}
                    actionBtnText={t('buttons.rescheduleSession')}
                    actionBtnCancelText={t('buttons.keepSession')}
                    mainMessage={t('modals.rescheduleBooking.mainMessage')}
                />
            )}

            {openInviteGuest && (
                <InviteGuestModal
                    isOpen={openInviteGuest}
                    setIsOpen={setOpenInviteGuest}
                    bookingId={booking._id}
                    bookingDate={booking.date}
                />
            )}
        </>
    )
})
