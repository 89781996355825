import OktaAuth from '@okta/okta-auth-js'
import { dehydrateToStorage, hydrateFromStorage } from './utils/misc'

export const getOktaConfigFromUrl = () => {
    const storedString = hydrateFromStorage('authConfig')

    let authConfig: { issuer?: string; clientId?: string } = {}
    if (typeof storedString === 'string') {
        authConfig = JSON.parse(storedString)
    }
    let oktaDomain
    if (authConfig.issuer) {
        const parsedUrl = new URL(authConfig.issuer)
        oktaDomain = parsedUrl.hostname
    }

    return {
        issuer: authConfig.issuer || `https://${oktaDomain}/oauth2/default`,
        clientId: authConfig.clientId as string,
        redirectUri: window.location.origin + '/login/callback',
    }
}

const searchParams = new URLSearchParams(window.location.search)
const encodedData = searchParams.get('details')
if (encodedData) {
    const decodedData = atob(encodedData)
    const [clientId, issuer] = decodedData.split(',')

    if (issuer && clientId) {
        const storageObject = {
            issuer: issuer,
            clientId: clientId,
        }

        const serializedStorageObject = JSON.stringify(storageObject)

        dehydrateToStorage('authConfig', serializedStorageObject)
    } else {
        console.error(
            'Issuer and clientId is undefined or empty after decoding.',
        )
    }
}

const oktaConfig = getOktaConfigFromUrl()

export const oktaAuthInfo = new OktaAuth({
    issuer: oktaConfig.issuer as string,
    clientId: oktaConfig.clientId,
    redirectUri: oktaConfig.redirectUri,
    scopes: ['openid', 'profile', 'email', 'offline_access'],
})
