import { format, parse, startOfToday } from 'date-fns'
import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { DashboardPageWrapper } from '../../components/navigation'
import { BookingModel } from '../../models/request/booking/booking.model'
import { RescheduleBookingModel } from '../../models/request/booking/reschedule-booking.model'
import {
    Availability,
    BookingResponse,
    Participant,
} from '../../models/response'
import { trackEvent } from '../../services/event-tracking'
import { NotificationType, RouteLink } from '../../utils/constants'
import {
    formatDate,
    formatTimeAndDate,
    getFormatString,
    getTimeFormatString,
} from '../../utils/misc'
import { useStores } from '../../utils/stores'
import InviteGuests from './components/InviteGuests'
import SelectTab from './components/SelectTab'
import SessionDuration from './components/SessionDuration'
import SessionTime from './components/SessionTime'
import SessionType from './components/SessionType'
import Calender from './components/calender'
import { OutOfTimeBooking } from './modals/late-time-booking.modal'

export const BookingPage: React.FC = observer(() => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const bookingId = query.get('bookingId')

    const { coachee, booking, notifications } = useStores()
    const today = startOfToday()

    const [selectedDay, setSelectedDay] = useState<Date>()
    const [duration, setDuration] = useState<number | undefined>()
    const [time, setTime] = useState('')
    const [type, setType] = useState('')
    const [isClicked, setIsClicked] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [timePeriod, setTimePeriod] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [participants, setParticipants] = useState<Participant[]>([])

    const [availability, setAvailability] = useState<Availability[]>([])
    const [currentMonth, setCurrentMonth] = useState(format(today, 'MMM-yyyy'))

    const parsedDate = parse(currentMonth, 'MMM-yyyy', new Date())
    const fullMonthName = format(parsedDate, 'MMMM').toLowerCase()

    const handleClickDuation = (
        value: React.SetStateAction<number | undefined>,
    ) => {
        setDuration(value)
    }

    const handleClickTime = (value: React.SetStateAction<string>) => {
        setTime(value)
    }

    const handleClickType = (value: React.SetStateAction<string>) => {
        setType(value)
    }

    useEffect(() => {
        if (coachee.coachee?.currentCoach?._id) {
            const subscription = coachee
                .getCoachAvailablility(
                    coachee.coachee?.currentCoach?._id,
                    fullMonthName,
                )
                .subscribe({
                    next: (response) => {
                        const data: any = response.data
                        setAvailability(data)
                    },
                })

            return () => {
                subscription.unsubscribe()
            }
        }
    }, [coachee, fullMonthName])

    useEffect(() => {
        if (!bookingId) {
            setSelectedDay(undefined)
            setDuration(undefined)
            setTime('')
            setType('')
            setParticipants([])
        } else {
            booking.getBooking(bookingId).subscribe({
                next: (response) => {
                    const data: any = response.data

                    setSelectedDay(new Date(data.date))
                    setCurrentMonth(format(new Date(data.date), 'MMM-yyyy'))
                    setDuration(data.duration)
                    setTime(format(new Date(data.date), getTimeFormatString()))
                    setType(data.isVideoSession ? 'Video' : 'Audio')
                    setParticipants(data.participants)
                },
            })
        }
    }, [booking, bookingId])

    let formattedDate = ''
    if (selectedDay) {
        const date = new Date(selectedDay)
        formattedDate = formatDate(date, getFormatString('long'))
    }

    const onEditCalender = () => {
        setSelectedDay(undefined)
        setDuration(undefined)
        setTime('')
        setType('')
    }

    const onEditDuration = () => {
        setDuration(undefined)
        setTime('')
        setType('')
    }

    const onEditTime = () => {
        setTime('')
        setType('')
    }

    const onEditType = () => {
        setType('')
    }

    const bookSession = (data: BookingModel | RescheduleBookingModel) => {
        setIsSubmitting(true)

        if (bookingId) {
            booking
                .rescheduleBooking(data as RescheduleBookingModel)
                .subscribe({
                    next(response) {
                        if (response.ok) {
                            trackEvent('session_rescheduled', {
                                coach_id: coachee.coachee?.currentCoach?._id,
                                coachee_id: coachee.coachee?._id,
                                booking_id: bookingId,
                                date: data.date,
                                duration: data.duration,
                                is_video_session: data.isVideoSession,
                            })
                            coachee.getCoachee().subscribe()
                            notifications.createNotification(
                                NotificationType.INFO,
                                t('messages.sessionBooked'),
                                6 * 1000,
                            )
                            navigate(RouteLink.DASHBOARD)
                        }
                    },
                    complete() {
                        setIsSubmitting(false)
                    },
                })
        } else {
            booking.createBookings(data as BookingModel).subscribe({
                next(response: BookingResponse) {
                    if (response.ok && response.data) {
                        trackEvent('session_booked', {
                            coach_id: coachee.coachee?.currentCoach?._id,
                            coachee_id: coachee.coachee?._id,
                            booking_id: response.data._id,
                            date: data.date,
                            duration: data.duration,
                            is_video_session: data.isVideoSession,
                        })
                        coachee.getCoachee().subscribe()
                        notifications.createNotification(
                            NotificationType.INFO,
                            t('messages.sessionBooked'),
                            6 * 1000,
                        )
                        navigate(RouteLink.DASHBOARD)
                    }

                    if (
                        response.status === 400 &&
                        response.message?.includes('Its too late to book')
                    ) {
                        notifications.createNotification(
                            NotificationType.ERROR,
                            t('booking.lateBooking'),
                            6 * 1000,
                        )
                    }
                    if (
                        response.status === 409 &&
                        response.message?.includes('You have already')
                    ) {
                        notifications.createNotification(
                            NotificationType.ERROR,
                            t('booking.maxNumOfBookings'),
                            6 * 1000,
                        )
                    }
                },
                complete() {
                    setIsSubmitting(false)
                },
            })
        }
    }

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault()
        setIsClicked(true)

        if (selectedDay && time) {
            let data
            const formattedDateTime = formatTimeAndDate(time, selectedDay)
            const hour = new Date(formattedDateTime).getHours()

            if (hour < 7 || hour > 21) {
                setTimePeriod(
                    hour < 7
                        ? t('modals.booking.early')
                        : t('modals.booking.late'),
                )
                setIsModalVisible(true)
                return
            }

            if (bookingId) {
                data = new RescheduleBookingModel(
                    bookingId || '',
                    formattedDateTime,
                    duration || 0,
                    type === 'Video' ? true : false,
                    participants,
                )
            } else {
                data = new BookingModel(
                    formattedDateTime,
                    duration || 0,
                    type === 'Video' ? true : false,
                    participants,
                )
            }
            bookSession(data)
        }
    }

    const onBookSession = () => {
        if (selectedDay && time) {
            const formattedDateTime = formatTimeAndDate(time, selectedDay)
            let data

            if (bookingId) {
                data = new RescheduleBookingModel(
                    bookingId || '',
                    formattedDateTime,
                    duration || 0,
                    type === 'Video' ? true : false,
                    participants,
                )
            } else {
                data = new BookingModel(
                    formattedDateTime,
                    duration || 0,
                    type === 'Video' ? true : false,
                    participants,
                )
            }
            bookSession(data)
        }
    }

    return (
        <DashboardPageWrapper
            pageName={t('pageTitles.booking')}
            backgroundColour="bg-[#f9fbfc]"
        >
            <div className="pb-16 max-w-[1268px] m-auto">
                <div className="lg:pt-16 pt-8">
                    {selectedDay ? (
                        <SelectTab
                            label={t('booking.selectDate')}
                            value={formattedDate}
                            onEdit={onEditCalender}
                        />
                    ) : (
                        <Calender
                            selectedDay={selectedDay}
                            currentMonth={currentMonth}
                            setSelectedDay={setSelectedDay}
                            setCurrentMonth={setCurrentMonth}
                            availability={availability}
                        />
                    )}
                </div>
                <div>
                    {selectedDay && !duration ? (
                        <SessionDuration
                            label={t('booking.selectDuration')}
                            handleClick={handleClickDuation}
                            availability={availability}
                            selectedDay={selectedDay}
                        />
                    ) : (
                        <SelectTab
                            label={t('booking.selectDuration')}
                            value={
                                duration
                                    ? t('dashboard.session.duration', {
                                          mins: duration,
                                      })
                                    : undefined
                            }
                            onEdit={onEditDuration}
                        />
                    )}
                </div>
                <div>
                    {selectedDay && duration && !time ? (
                        <SessionTime
                            label={t('booking.selectTime')}
                            selectedDay={selectedDay}
                            availability={availability}
                            handleClick={handleClickTime}
                            duration={duration}
                        />
                    ) : (
                        <SelectTab
                            label={t('booking.selectTime')}
                            value={time}
                            onEdit={onEditTime}
                        />
                    )}
                </div>
                <div>
                    {selectedDay && duration && time && !type ? (
                        <SessionType
                            label={t('booking.selectType')}
                            type={type}
                            handleClick={handleClickType}
                        />
                    ) : (
                        <SelectTab
                            label={t('booking.selectType')}
                            value={
                                type === 'Video'
                                    ? t('booking.video')
                                    : type
                                    ? t('booking.call')
                                    : undefined
                            }
                            onEdit={onEditType}
                        />
                    )}
                </div>
                <div>
                    {selectedDay && duration && time && type ? (
                        <InviteGuests
                            label={t('booking.bookSession')}
                            handleSubmit={handleSubmit}
                            isClicked={isClicked}
                            setParticipants={setParticipants}
                            participants={participants}
                            isSubmitting={isSubmitting}
                        />
                    ) : (
                        <SelectTab label={t('booking.bookSession')} />
                    )}
                </div>
                <OutOfTimeBooking
                    isOpen={isModalVisible}
                    setIsOpen={() => setIsModalVisible(false)}
                    onBookSession={onBookSession}
                    timePeriod={timePeriod}
                />
            </div>
        </DashboardPageWrapper>
    )
})
