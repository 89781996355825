import { observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../components/buttons/primary-button'
import { SideModal } from '../../components/modals/side-modal'
import SideModalSkeleton from '../../components/skeleton/sideSkeleton'
import { trackEvent } from '../../services/event-tracking'
import { classNames } from '../../utils/misc'
import { useStores } from '../../utils/stores'
import { GoalTabs } from './components/goalsTab'
import { GoalsModal } from './modals/goals.modal'
import { SmartGoalModal } from './modals/smartgoals.modal'

interface Props {
    isOpen: boolean
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const Goals: React.FC<Props> = observer(({ isOpen, setIsOpen }) => {
    const { t } = useTranslation()
    const { coachee } = useStores()
    const [openAddGoal, setOpenAddGoal] = useState(false)
    const [openSmartGoals, setOpenSmartGoal] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        const subscription = coachee.getCoacheeGoals().subscribe({
            next: () => {
                setIsLoading(false)
            },
            error: () => {
                setIsLoading(false)
            },
        })

        return () => {
            subscription.unsubscribe()
        }
    }, [coachee])

    const smartGoalOpenAction = () => {
        setOpenSmartGoal(true)
    }

    return (
        <SideModal
            isOpen={isOpen}
            setIsOpen={() => setIsOpen(false)}
            className="max-w-[28rem]"
            title="goal_list_opened"
        >
            <div className="rounded-lg bg-white max-w-[28rem] w-full">
                <div className="flex  flex-col px-8 pt-10 pb-5 sticky top-0 z-20 bg-white">
                    <span className="text-[38px]">{t('goals.title')}</span>
                    <span className="text-[19px]">{t('goals.subtitle')}</span>
                    <span
                        className="text-[17px] mt-1 cursor-pointer font-semibold"
                        onClick={smartGoalOpenAction}
                    >
                        {t('goals.smartGoals')}
                    </span>
                </div>
                {isLoading ? <SideModalSkeleton /> : <GoalTabs />}

                <div
                    className={classNames(
                        'flex',
                        'w-[25rem]',
                        'fixed bottom-0 right-5',
                        'mx-auto',
                        'mb-10',
                        'bg-white p-5 rounded-[50px] shadow-lg',
                    )}
                >
                    <PrimaryButton
                        className="w-full  max-w-[30rem] btn-primary"
                        onClick={() => {
                            trackEvent('screen_viewed', {
                                name: 'goal_create_opened',
                                coachee_id: coachee.coachee?._id,
                            })
                            setOpenAddGoal(true)
                        }}
                    >
                        <span>{t('goals.addGoal')}</span>
                    </PrimaryButton>
                </div>
                {openAddGoal && (
                    <GoalsModal
                        isOpen={openAddGoal}
                        setIsOpen={setOpenAddGoal}
                        title={t('goals.addGoal')}
                    />
                )}
                {openSmartGoals && (
                    <SmartGoalModal
                        isOpen={openSmartGoals}
                        setIsOpen={() => setOpenSmartGoal(false)}
                    />
                )}
            </div>
        </SideModal>
    )
})
