import 'chart.js/auto'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { Chart } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'
import { PrimaryButton } from '../../../components/buttons/primary-button'
import { TrackerData } from '../../../models/response'
import { formatDate, getFormatString } from '../../../utils/misc'
import { useStores } from '../../../utils/stores.js'

interface Legend {
    label: string
    visible: boolean
}

export const Trackers = observer(() => {
    const { coachee } = useStores()
    const { t } = useTranslation()

    const [graphData, setGraphData] = useState({
        datasets: [],
        labels: [],
    } as any)
    const [legends, setLegends] = useState<Legend[]>([])
    const [data, setData] = useState<TrackerData>({})
    const [smallerViewport, setSmallerViewport] = useState(
        window.innerWidth < 800,
    )

    const updateSize = useCallback(() => {
        if (window.innerWidth < 800) {
            setSmallerViewport(true)
        } else {
            setSmallerViewport(false)
        }
    }, [])

    useEffect(() => {
        window.addEventListener('resize', updateSize)

        return () => window.removeEventListener('resize', updateSize)
    }, [updateSize])

    useEffect(() => {
        const subscription = coachee?.getTrackerData()?.subscribe({
            next: (response) => {
                const data = response.data
                setData(data)
            },
        })

        return () => {
            subscription?.unsubscribe()
        }
    }, [coachee])

    useEffect(() => {
        const processData = () => {
            if (data) {
                let labels: Array<string> = []
                const categories = Object.keys(data)

                const firstKeyWithArray = Object.keys(data).find((key) =>
                    Array.isArray(data[key]),
                )

                if (firstKeyWithArray) {
                    labels = data[firstKeyWithArray].map((responses) => {
                        const dateArray = responses.date.split('-')
                        return formatDate(
                            new Date(
                                parseInt(dateArray[2]),
                                parseInt(dateArray[1]) - 1,
                                parseInt(dateArray[0]),
                            ),
                            getFormatString('long'),
                        )
                    })
                }

                const dataSets = categories.map((category) => {
                    const categoryColour = getCategoryColour(category, true)
                    return {
                        label: category,
                        spanGaps: true,
                        fill: false,
                        lineTension: 0.3,
                        backgroundColor: categoryColour,
                        borderColor: categoryColour,
                        borderCapStyle: 'butt',
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: 'miter',
                        pointBorderColor: categoryColour,
                        pointBorderWidth: 1,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: categoryColour,
                        pointHoverBorderColor: categoryColour,
                        pointBackgroundColor: categoryColour,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        hidden: false,
                        data: data[category].map((item) => item.value),
                    }
                })

                const legends = categories.map((category) => ({
                    label: category,
                    visible: true,
                }))

                const dataObject = {
                    labels: labels,
                    datasets: dataSets,
                }

                setLegends(legends)
                setGraphData(dataObject)
            }
        }

        processData()
    }, [data])

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        scales: {
            y: {
                suggestedMin: 0,
                suggestedMax: 5,
                ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                },
                grid: {
                    display: true,
                    color: '#E4E4EA',
                },
                title: {
                    display: true,
                    text: t('leaderboard.rating'),
                },
            },
            x: {
                ticks: {
                    maxRotation: 45,
                    minRotation: 45,
                },
            },
        },
    }

    const getCategoryColour = (category: string, visible: boolean) => {
        if (!visible) {
            return 'rgb(243,244,244)'
        } else {
            switch (category) {
                case 'adaptability':
                    return 'rgb(46,123,139)'
                case 'alignment':
                    return 'rgb(80,145,174)'
                case 'motivation':
                    return 'rgb(130,8,59)'
                case 'resilience':
                    return 'rgb(255,103,67)'
                case 'self-efficacy':
                    return 'rgb(61,41,127)'
            }
        }
    }

    const showLines = (name: string) => {
        const newLegends = legends.map((legend) => {
            if (legend.label === name) {
                return {
                    label: name,
                    visible: !legend.visible,
                }
            } else {
                return legend
            }
        })

        setLegends(newLegends)

        const newDataSets = graphData.datasets.map((data: any) =>
            data.label === name ? { ...data, hidden: !data.hidden } : data,
        )

        const newDatas = {
            labels: graphData.labels,
            datasets: newDataSets,
            options: graphData.options,
        }

        setGraphData(newDatas)
    }

    const labelsFilter = legends.map((item, index) => (
        <PrimaryButton
            className={classNames(
                'inline-flex items-center justify-center w-fit h-10 rounded-full mr-2.5 cursor-pointer mb-7.5 text-[17px]',
                !item.visible && 'text-primary',
            )}
            style={{
                backgroundColor: getCategoryColour(item.label, item.visible),
            }}
            key={index}
            onClick={() => showLines(item.label)}
        >
            {t(`leaderboard.tracker.${item.label}`)}
        </PrimaryButton>
    ))

    return (
        <div>
            <Chart
                type="line"
                data={graphData}
                options={options}
                height={smallerViewport ? '400px' : '100px'}
            />
            <div className="mt-10">
                <span className="flex flex-wrap gap-3 items-center justify-center">
                    {labelsFilter}
                </span>
            </div>
        </div>
    )
})

export default Trackers
