import { Message } from '@twilio/conversations'
import { observer } from 'mobx-react'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TypingDot from '../../../assets/animations/typing-dots.gif'
import ChatSkeleton from '../../../components/skeleton/chatSkeleton'
import { trackEvent } from '../../../services/event-tracking'
import { mapSingleMessage } from '../../../utils/chatHelper'
import {
    classNames,
    formatDate,
    getFormatString,
    getTimeFormatString,
} from '../../../utils/misc'
import { groupMessagesByDate, sortMessages } from '../../../utils/sort'
import { useStores } from '../../../utils/stores'
import { EmptyChat } from './emptyChat'
import { MessagesPerMinute } from './messagePerMinute'

interface Props {
    id?: string
    coachId?: string
    coachInitial?: string
}
export const ChatSection: React.FC<Props> = observer(
    ({ id, coachId, coachInitial }) => {
        const { t } = useTranslation()
        const { channel, coachee, image } = useStores()
        const [isLoading, setIsLoading] = useState(true)
        const bottomRef = useRef<HTMLDivElement>(null)
        const [isTyping, setIsTyping] = useState(false)

        const channelId = id ?? channel.activeChannel?.twilio.id

        useEffect(() => {
            setIsLoading(true)
            const subscription = channel.getCoacheeChannel().subscribe({
                next(response) {
                    if (response.data) {
                        channel.getChannelToken().subscribe({
                            next(response) {
                                if (response.data) {
                                    channel.getChatClient()
                                    channel
                                        .getActiveChannelMessages(
                                            channelId ?? '',
                                        )
                                        .finally(() => {
                                            setIsLoading(false) // Set loading to false once data is fetched
                                        })
                                }
                            },
                        })
                    } else {
                        setIsLoading(false) // Ensure loading is set to false if there's no data
                    }
                },
            })

            return () => {
                subscription.unsubscribe()
            }
        }, [
            channel,
            channelId,
            coachee.coachee?._id,
            coachee.coachee?.currentCoach._id,
        ])

        useEffect(() => {
            if (channel.conversation) {
                const handleNewMessage = (message: Message) => {
                    const newMessage = mapSingleMessage(message)
                    if (
                        !channel.activeChannelMessages.find(
                            (m) => m.sid === newMessage.sid,
                        )
                    ) {
                        channel.setActiveChannelMessage([
                            ...channel.activeChannelMessages,
                            newMessage,
                        ])
                        channel.conversation?.setAllMessagesRead()
                    }
                }

                channel.conversation.on('messageAdded', handleNewMessage)

                return () => {
                    if (channel.conversation) {
                        channel.conversation.off(
                            'messageAdded',
                            handleNewMessage,
                        )
                    }
                }
            }
        }, [channel, channel.conversation])

        useEffect(() => {
            if (channel.conversation) {
                const handleTypingStarted = () => {
                    setIsTyping(true)
                }

                const handleTypingEnded = () => {
                    setIsTyping(false)
                }

                channel.conversation.on('typingStarted', handleTypingStarted)
                channel.conversation.on('typingEnded', handleTypingEnded)

                return () => {
                    if (channel.conversation) {
                        channel.conversation.off(
                            'typingStarted',
                            handleTypingStarted,
                        )
                        channel.conversation.off(
                            'typingEnded',
                            handleTypingEnded,
                        )
                    }
                }
            }
        }, [channel, channel.conversation])

        useEffect(() => {
            bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
        }, [channel.activeChannelMessages, isLoading, isTyping])

        const groupedMessages = groupMessagesByDate(
            channel.activeChannelMessages,
        )

        useEffect(() => {
            trackEvent('chat_messaging_open', {
                coach_id: coachee.coachee?.currentCoach._id,
                coachee_id: coachee.coachee?._id,
            })
            return () => {
                trackEvent('chat_messaging_closed', {
                    coach_id: coachee.coachee?.currentCoach._id,
                    coachee_id: coachee.coachee?._id,
                })
            }
        }, [coachee.coachee?._id, coachee.coachee?.currentCoach._id])

        return (
            <div
                className={classNames(
                    isLoading ? '' : 'm-auto w-full max-w-[1024px]',
                    'md:px-12 md:py-16  px-5  pb-[6rem]',
                )}
            >
                {isLoading ? (
                    <ChatSkeleton />
                ) : channel.activeChannelMessages.length !== 0 ? (
                    <>
                        {sortMessages(groupedMessages).map((date, i) => {
                            const dailyMessages = groupMessagesByDate(
                                groupedMessages[date],
                                getTimeFormatString(),
                            )

                            return (
                                <div key={i}>
                                    <div
                                        className={classNames(
                                            'flex items-center text-center ',
                                            i == 0 && 'mt-[76px] md:mt-0',
                                        )}
                                    >
                                        <div className="flex-grow h-[1px] bg-[#e9eced]"></div>
                                        <div className="mx-2 text-[#3a3943]">
                                            {formatDate(
                                                groupedMessages[date][0]
                                                    .dateCreated as Date,
                                                getFormatString('extra-long'),
                                            )}
                                        </div>
                                        <div className="flex-grow h-[1px] bg-[#e9eced]"></div>
                                    </div>
                                    {sortMessages(dailyMessages).map(
                                        (date, index) => {
                                            const isCoachMsg =
                                                dailyMessages[date][0]
                                                    .sender ===
                                                coachee.coachee?.currentCoach
                                                    ._id
                                            return (
                                                <MessagesPerMinute
                                                    key={index}
                                                    isCoachMsg={isCoachMsg}
                                                    date={date}
                                                    coachId={coachId ?? ''}
                                                    coachimage={
                                                        image.coachPicture ?? ''
                                                    }
                                                    dailyMessages={
                                                        dailyMessages
                                                    }
                                                />
                                            )
                                        },
                                    )}
                                    <div ref={bottomRef} />
                                </div>
                            )
                        })}
                        {coachId && (
                            <div className={`flex items-center text-center`}>
                                <div className="flex-grow h-[1px] bg-[#e9eced]"></div>
                                <span className="mx-2 text-[#3a3943] text-xl">
                                    {t('chat.cantReply')}
                                </span>
                                <div className="flex-grow h-[1px] bg-[#e9eced]"></div>
                            </div>
                        )}
                    </>
                ) : (
                    <EmptyChat coachId={coachId} coachInitial={coachInitial} />
                )}
                {isTyping && (
                    <div className="max-w-[15%] md:max-w-[7%] pb-8">
                        <img src={TypingDot} alt={t('alt.typing')} />
                    </div>
                )}
            </div>
        )
    },
)
