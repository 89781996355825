import { FFmpeg } from '@ffmpeg/ffmpeg'
import { toBlobURL } from '@ffmpeg/util'
import { observer } from 'mobx-react'
import { FC, useCallback, useEffect, useState } from 'react'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import { useTranslation } from 'react-i18next'
import playingAudioWave from '../../../assets/animations/mp3/recording.gif'
import binIcon from '../../../assets/icons/bin-icon.svg'
import urgentIconActive from '../../../assets/icons/info-icon.svg'
import pausedAudioWave from '../../../assets/icons/pause-recording.svg'
import pauseIcon from '../../../assets/icons/pause.svg'
import playIcon from '../../../assets/icons/play-icon.svg'
import sendIcon from '../../../assets/icons/send.svg'
import urgentIcon from '../../../assets/icons/urgent.svg'
import { ChatModel } from '../../../models/request/chat'
import { trackEvent } from '../../../services/event-tracking'
import { NotificationType } from '../../../utils/constants'
import { classNames, formatDuration } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'
import { FileLoader } from './uploadLoader'

interface VoiceRecordProp {
    close: () => void
}

export const VoiceRecord: FC<VoiceRecordProp> = observer(({ close }) => {
    const [isUrgent, setIsUrgent] = useState(false)
    const { channel, notifications, coachee } = useStores()
    const [sendAudioInfo, setSendAudioInfo] = useState({
        progress: 0,
        isSending: false,
    })
    const [isPlaying, setIsPlaying] = useState(false)
    const [finalRecordingLength, setFinalRecordingLength] = useState(0)
    const [recordedMessage, setRecordedMessage] = useState<Blob | null>(null)
    const { t } = useTranslation()
    const {
        startRecording,
        stopRecording,
        recordingBlob,
        isRecording,
        isPaused,
        recordingTime,
    } = useAudioRecorder(undefined, undefined)
    const baseURL = '/ffmpeg'

    useEffect(() => {
        if (finalRecordingLength <= 0) return
        if (!isPlaying) return
        const intervalId = setInterval(() => {
            setFinalRecordingLength((prevTime) => prevTime - 1)
        }, 1000)

        return () => clearInterval(intervalId)
    }, [finalRecordingLength, isPlaying])

    const convertWebmToMp3 = useCallback(
        async (webmBlob: Blob): Promise<Blob> => {
            if (!recordedMessage) {
                const ffmpeg = new FFmpeg()
                await ffmpeg.load({
                    coreURL: await toBlobURL(
                        `${baseURL}/ffmpeg-core.js`,
                        'text/javascript',
                    ),
                    wasmURL: await toBlobURL(
                        `${baseURL}/ffmpeg-core.wasm`,
                        'application/wasm',
                    ),
                    workerURL: await toBlobURL(
                        `${baseURL}/ffmpeg-core.worker.js`,
                        'text/javascript',
                    ),
                })
                const inputName = 'input.webm'
                const outputName = 'output.mp3'

                await ffmpeg.writeFile(
                    inputName,
                    new Uint8Array(await webmBlob.arrayBuffer()),
                )
                await ffmpeg.exec(['-i', inputName, outputName], 10000)
                const outputData = await ffmpeg.readFile(outputName)
                const file = new Blob([outputData], { type: 'audio/mp4' })
                setRecordedMessage(file)
                return Promise.resolve(file)
            } else {
                return Promise.resolve(recordedMessage)
            }
        },
        [recordedMessage],
    )

    const sendAudioMessage = useCallback(async () => {
        if (recordingBlob) {
            setSendAudioInfo({
                progress: 50,
                isSending: true,
            })
            const formData = new FormData()
            const audioBlob = await convertWebmToMp3(recordingBlob)
            formData.append(
                'file',
                audioBlob as Blob,
                `recording-${Date.now()}.mp3`,
            )
            const chatModel = new ChatModel()
            chatModel.file = formData
            chatModel.fileBytesSize = audioBlob.size ?? 0
            chatModel.isUrgent = isUrgent
            chatModel.duration = finalRecordingLength

            channel.postChatMessage(chatModel, true).subscribe({
                next(response) {
                    if (response.ok) {
                        close()
                        trackEvent('voice_message_sent', {
                            coach_id: coachee.coachee?.currentCoach._id,
                            coachee_id: coachee.coachee?._id,
                            is_urgent: isUrgent,
                        })

                        if (isUrgent) {
                            notifications.createNotification(
                                NotificationType.INFO,
                                t('messages.urgentMessage'),
                                5 * 1000 /* 5 seconds */,
                                true,
                            )
                        }
                    }
                },
                complete() {
                    setSendAudioInfo({
                        progress: 100,
                        isSending: false,
                    })
                    setRecordedMessage(null)
                },
            })
        }
    }, [
        channel,
        close,
        coachee.coachee?._id,
        coachee.coachee?.currentCoach._id,
        convertWebmToMp3,
        finalRecordingLength,
        isUrgent,
        notifications,
        recordingBlob,
        t,
    ])

    return (
        <div className="relative flex flex-col">
            <div
                className={classNames(
                    'flex items-center h-[80px] lg:px-10 bg-white fixed bottom-0 w-full',
                    'lg:w-[calc(100vw_-_310px)] md:w-[calc(100vw_-_250px)]',
                )}
            >
                {sendAudioInfo.isSending && (
                    <FileLoader
                        progress={sendAudioInfo.progress}
                        filename={`${recordedMessage?.size} ${recordedMessage?.type}`}
                    />
                )}
                <img
                    src={isUrgent ? urgentIconActive : urgentIcon}
                    alt="ugrent icon"
                    className="w-6 h-6 md:w-7 absolute left-10 lg:left-0 z-50 md:h-7 cursor-pointer "
                    onClick={() => setIsUrgent(!isUrgent)}
                />
                <img
                    src={binIcon}
                    onClick={() => {
                        setRecordedMessage(null)
                        close()
                    }}
                    width={14}
                    className="ml-2 cursor-pointer lg:mr-2 mr-2 lg:ml-4"
                />
                <div
                    className={classNames(
                        'flex items-center relative',
                        'lg:w-[92%] w-[85%] mr-3  h-[40px] rounded-[20px] bg-[#FF7558]',
                        isRecording ? 'justify-center' : 'justify-start',
                    )}
                >
                    <img
                        src={isRecording ? playingAudioWave : pausedAudioWave}
                        alt="sound-wave"
                        width={400}
                        height="25px"
                        className={classNames(
                            'h-[35px]',
                            isRecording
                                ? 'lg:w-[600px]  w-[220px]'
                                : 'lg:w-[900px] md:w-[280px] w-[220px] lg:h-[35px] md:h-[20px] ml-12 md:ml-0',
                        )}
                    />
                    <p
                        id="timer"
                        className="text-white absolute text-[14px] right-9 md:right-20 -bottom-3"
                    >
                        {isRecording
                            ? formatDuration(recordingTime)
                            : formatDuration(finalRecordingLength)}
                    </p>
                    {isRecording && (
                        <img
                            src={pauseIcon}
                            onClick={() => {
                                stopRecording()
                                setFinalRecordingLength(recordingTime)
                            }}
                            height="16px"
                            className="h-[25px] md:h-[30px] cursor-pointer right-2 md:right-8 absolute"
                        />
                    )}
                    {!isRecording && !isPaused && !recordingBlob && (
                        <img
                            src={playIcon}
                            onClick={() => startRecording()}
                            height="16px"
                            className="h-[25px] md:h-[30px] cursor-pointer right-2 md:right-8 absolute"
                        />
                    )}
                    {recordingBlob && (
                        <img
                            src={playIcon}
                            onClick={async () => {
                                const blob = await convertWebmToMp3(
                                    recordingBlob,
                                )
                                if (blob) {
                                    const audio = new Audio(
                                        URL.createObjectURL(blob),
                                    )
                                    audio.play()
                                    setIsPlaying(true)
                                }
                            }}
                            height="16px"
                            className="h-[25px] md:h-[30px] items-end absolute right-2 md:right-8 justify-end cursor-pointer"
                        />
                    )}
                </div>
                <button
                    type="submit"
                    disabled={isRecording || sendAudioInfo.isSending}
                    onClick={sendAudioMessage}
                    className={classNames(
                        'w-[30px]  right-4 ml-3 md:w-[40px]',
                        (isRecording || sendAudioInfo.isSending) &&
                            'opacity-35',
                    )}
                >
                    <img src={sendIcon} alt="send" />
                </button>
            </div>
        </div>
    )
})
