import { observer } from 'mobx-react'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SoundbarCoachee from '../../../assets/animations/mp3/soundbar-animation.gif'
import SoundbarCoach from '../../../assets/animations/mp3/soundbar-animation_coach.gif'
import UrgentIcon from '../../../assets/icons/info-icon.svg'
import PauseIcon from '../../../assets/icons/pause.svg'
import PlayActiveIcon from '../../../assets/icons/play-active.svg'
import PlayIcon from '../../../assets/icons/play.svg'
import SoundbarCoacheeIcon from '../../../assets/icons/soundbar-animation.svg'
import SoundbarCoachIcon from '../../../assets/icons/soundbar-animation_coach.svg'
import UrgentCoachIcon from '../../../assets/icons/urgent_coach.svg'
import { MediaDetails } from '../../../models/response/channel'
import { getMp3 } from '../../../utils/chatHelper'
import { classNames, formatDuration } from '../../../utils/misc'
import { useStores } from '../../../utils/stores'

interface Props {
    mediaDetail: MediaDetails
    isCoachMsg: boolean
    isUrgent: boolean
}

enum PlayStatus {
    STOPPED,
    PLAYING,
    PAUSED,
}

export const AudioMessage: React.FC<Props> = observer(
    ({ mediaDetail, isCoachMsg, isUrgent }) => {
        const { channel } = useStores()
        const { t } = useTranslation()
        const [player, setPlayer] = useState<HTMLAudioElement | null>(null)
        const [duration, setDuration] = useState<number | null>(null)
        const [countdown, setCountdown] = useState<number | null>(null)
        const [playStatus, setPlayStatus] = useState<PlayStatus>(
            PlayStatus.STOPPED,
        )
        const Icon = isCoachMsg ? PlayActiveIcon : PlayIcon
        const soundbar = isCoachMsg ? SoundbarCoach : SoundbarCoachee
        const soundbarIcon = isCoachMsg
            ? SoundbarCoachIcon
            : SoundbarCoacheeIcon

        const getTempUrl = useCallback(
            async (mediaSid: string) => {
                if (channel.chatClient) {
                    const urls =
                        await channel.chatClient.getTemporaryContentUrlsForMediaSids(
                            [mediaSid],
                        )
                    const fileUrl = urls.get(mediaSid)
                    return fileUrl
                }
            },
            [channel.chatClient],
        )

        useEffect(() => {
            const fetchData = async () => {
                if (mediaDetail.contentType.includes('audio') && player) {
                    const url = await getTempUrl(mediaDetail.sid)
                    if (!player.src) {
                        getMp3(url ?? '').then((url: string) => {
                            player.src = url
                        })
                    }

                    if (playStatus === PlayStatus.PLAYING) {
                        player.play()
                    } else if (playStatus === PlayStatus.PAUSED) {
                        player.pause()
                    } else if (playStatus === PlayStatus.STOPPED) {
                        player.pause()
                        player.currentTime = 0
                    }
                }
            }

            fetchData()
        }, [
            channel,
            getTempUrl,
            mediaDetail.contentType,
            mediaDetail.sid,
            playStatus,
            player,
        ])

        useEffect(() => {
            let interval: NodeJS.Timeout
            if (playStatus === PlayStatus.PLAYING) {
                interval = setInterval(() => {
                    setCountdown((prevCountdown) =>
                        prevCountdown !== null ? prevCountdown - 1 : null,
                    )
                }, 1000)
            }

            return () => {
                if (interval) {
                    clearInterval(interval)
                }
            }
        }, [playStatus, player, duration])

        const handlePlay = () => {
            if (playStatus === PlayStatus.PLAYING) {
                setPlayStatus(PlayStatus.PAUSED)
            } else if (
                playStatus === PlayStatus.PAUSED ||
                playStatus === PlayStatus.STOPPED
            ) {
                setPlayStatus(PlayStatus.PLAYING)
            }
        }

        useEffect(() => {
            if (player) {
                const handleLoadedMetadata = () => {
                    const durationInSeconds = player.duration
                    setDuration(durationInSeconds)
                    setCountdown(durationInSeconds)
                }

                player.addEventListener('loadedmetadata', handleLoadedMetadata)

                return () => {
                    player.removeEventListener(
                        'loadedmetadata',
                        handleLoadedMetadata,
                    )
                }
            }
        }, [player])

        return (
            <div className="flex items-center gap-4">
                <img
                    src={playStatus !== PlayStatus.PLAYING ? Icon : PauseIcon}
                    alt="Play"
                    className="h-8 w-8 cursor-pointer"
                    onClick={handlePlay}
                />
                <div>
                    <img
                        src={
                            playStatus === PlayStatus.PLAYING
                                ? soundbar
                                : soundbarIcon
                        }
                        alt="soundbar"
                        className={classNames(
                            playStatus !== PlayStatus.PLAYING
                                ? 'h-[2rem]'
                                : 'h-12',
                            'w-[15rem]',
                        )}
                    />
                    <div className="flex justify-between">
                        <span className="text-sm">
                            {t('chat.voiceMessage')}
                        </span>
                        <span className="text-sm">
                            {countdown && formatDuration(countdown)}
                        </span>
                    </div>
                </div>
                <audio
                    ref={(ref) => setPlayer(ref)}
                    onPlay={() => setPlayStatus(PlayStatus.PLAYING)}
                    onPause={() => setPlayStatus(PlayStatus.PAUSED)}
                    onEnded={() => {
                        setPlayStatus(PlayStatus.STOPPED)
                        setCountdown(duration)
                    }}
                />
                {isUrgent && (
                    <img
                        src={isCoachMsg ? UrgentCoachIcon : UrgentIcon}
                        alt="Urgent"
                        className="h-4 w-4 absolute bottom-0 right-0  mb-1 mr-1"
                    />
                )}
            </div>
        )
    },
)
